
































































































































































import Vue from "vue";
import VaSwitch from "@/components/atoms/VaSwitch.vue";
import VaTextField from "@/components/atoms/VaTextField.vue";
import validationMixin from "@/components/mixins/validationMixin";
import policiesMixin from "@/components/mixins/policiesMixin";

export default Vue.extend({
  name: "AdvancedConfiguration",
  components: {
    VaTextField,
    VaSwitch,
  },
  mixins: [validationMixin, policiesMixin],
  data() {
    return {};
  },
});
