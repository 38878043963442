import axios, { AxiosResponse } from "axios";
import apiService from "./apiService";
import { OptionsTable, QueryResult } from "@/models/table";
import logger from "@/services/loggerService";
import {
  PolicyDeserializer,
  PolicySearchSerializer,
  PolicyFieldsDeserializer,
  Policy,
  PolicySerializer,
  PoliciesGroups,
  groupsDecoder,
  possibleGroups,
} from "@/models/policy";

export default {
  async search(
    options: OptionsTable,
    searchString?: string,
    groupFilter = possibleGroups[0] as PoliciesGroups
  ): Promise<QueryResult> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const groupId = groupsDecoder[groupFilter];
    const customUrl = `${apiService.policy.searchUrl()}/${groupId}`;
    const data = PolicySearchSerializer(options, searchString);
    const res: AxiosResponse = await axios.post(customUrl, data);
    return {
      items: res.data.items,
      count: res.data.count,
    } as QueryResult;
  },
  async get(id: string): Promise<Policy | undefined> {
    //TODO MISSING API METHOD - temporary getting from list NEEDS MAJOR REFACTOR
    const data = {
      limit: 1000,
      page: 1,
      sortBy: [],
      filterBy: [],
    };
    const res: AxiosResponse = await axios.post(
      apiService.policy.searchUrl(),
      data
    );
    const rawpolicy = res.data.items.find((policy: any) => {
      return policy.policyId === id;
    });
    const policy = PolicyDeserializer(rawpolicy);
    try {
      const res = await axios.get(
        apiService.policy.fieldsUrl().replace(":id", id)
      );
      policy.fields = PolicyFieldsDeserializer(res.data);
      return policy;
      // return PolicyDetailedDeserializer(res.data, policy);
    } catch (e) {
      logger.error(e);
    }
  },
  async groups(): Promise<Array<Record<string, string | number>>> {
    const res = await axios.get(apiService.policy.groupsUrl());
    return res.data;
  },
  async post(policy: Policy): Promise<string> {
    const res = await axios.post(
      apiService.policy.baseUrl(),
      PolicySerializer(policy)
      // PolicyDetailedSerializer(policy),
    );
    return res.data;
  },
  async put(policy: Policy): Promise<string> {
    const res = await axios.put(
      apiService.policy.baseUrl() + "/" + policy.id,
      PolicySerializer(policy)
      // PolicyDetailedSerializer(policy),
    );
    return res.data;
  },
  async delete(id: string): Promise<AxiosResponse> {
    return await axios.delete(apiService.policy.baseUrl() + "/" + id);
  },
  async fields(policyId: string): Promise<Array<any>> {
    const url = apiService.policy.fieldsUrl().replace(":id", policyId);
    const res = await axios.get(url);
    return res.data;
  },
  validateFileFields(policy: Policy): string | undefined {
    //HACK custom validation for some policies with files
    if (policy.fields) {
      if (
        policy.fields.CustomLogoActive?.keyValue == true &&
        !policy.fields.Logo?.keyValue
      ) {
        return "Logo field is required";
      }
      if (
        policy.fields.CustomBackgroundActive?.keyValue == true &&
        !policy.fields.Backgrounds?.keyValue
      ) {
        return "Background field is required";
      }
      if (
        policy.fields.ShowCustomBackground?.keyValue == true &&
        !policy.fields.WorkspaceBackground?.keyValue
      ) {
        return "Custom background field is required";
      }
    }
  },
  clearPolicyFields(policy: Policy): Policy {
    //HACK enforce some business logic constraints
    if (policy.fields) {
      if (
        policy.type == "appearance" &&
        !policy.fields.VoiceCommandsActive?.keyValue
      ) {
        policy.fields.VoiceCommandsCultureKey = {
          keyName: "VoiceCommandsCultureKey",
          keyValue: "en-US",
          keyType: "string",
        };
        policy.fields.DisableVoiceSuggestion = {
          keyName: "DisableVoiceSuggestion",
          keyValue: false,
          keyType: "boolean",
        };
      }
      if (
        policy.type == "calendar" &&
        policy.fields.DefaultExtendCountdownAction?.keyValue != "0"
      ) {
        policy.fields.NotifyEndMeetingTimeout = {
          keyName: "NotifyEndMeetingTimeout",
          keyValue: 30,
          keyType: "int",
        };
      }
      if (policy.type == "calendar") {
        policy.fields.NotifyEndMeetingTimeoutEnabled = {
          keyName: "NotifyEndMeetingTimeoutEnabled",
          keyValue: true,
          keyType: "boolean",
        };
      }
      if (policy.fields.CustomLogoActive?.keyValue == false) {
        policy.fields.Logo = {
          keyName: "Logo",
          keyValue: "",
          keyType: "file",
        };
      }
      if (policy.fields.CustomBackgroundActive?.keyValue == false) {
        policy.fields.Backgrounds = {
          keyName: "Backgrounds",
          keyValue: "",
          keyType: "file",
        };
      }
      if (policy.fields.DigitalSignageActive?.keyValue == false) {
        policy.fields.WebUrlBackground = {
          keyName: "WebUrlBackground",
          keyValue: "",
          keyType: "string",
        };
      }
      if (policy.fields.PeopleCountEnabled?.keyValue == false) {
        // PeopleCountEnabled
        // PeopleCountOnScreenNotification
        policy.fields.PeopleCountRoomCapacity.keyValue = 0;
        policy.fields.PeopleCountOnScreenNotification.keyValue = false;
      }

      if (
        policy.fields.PeopleCountEnabled?.keyValue == true &&
        policy.fields.PeopleCountRoomCapacity.keyValue == 0
      ) {
        policy.fields.PeopleCountRoomCapacity.keyValue = 1;
      }

      if (policy.fields.ShowWifiData?.keyValue == false) {
        policy.fields.WifiSSID = {
          keyName: "WifiSSID",
          keyValue: "",
          keyType: "string",
        };
        policy.fields.WifiPassword = {
          keyName: "WifiPassword",
          keyValue: "",
          keyType: "string",
        };
      }
      if (policy.fields.CrestronEnabled?.keyValue == false) {
        policy.fields.CrestronUrl = {
          keyName: "CrestronUrl",
          keyValue: "",
          keyType: "string",
        };
        policy.fields.CrestronUsername = {
          keyName: "CrestronUsername",
          keyValue: "",
          keyType: "string",
        };
        policy.fields.CrestronPassword = {
          keyName: "CrestronPassword",
          keyValue: "",
          keyType: "string",
        };
      }
      if (policy.fields.ShowCustomBackground?.keyValue == false) {
        policy.fields.WorkspaceBackground = {
          keyName: "WorkspaceBackground",
          keyValue: "",
          keyType: "string",
        };
      }
      if (policy.fields.IsHealthCheckEnabled?.keyValue == false) {
        policy.fields.HealthCheckSpeakerMute = {
          keyName: "HealthCheckSpeakerMute",
          keyValue: false,
          keyType: "boolean",
        };
        policy.fields.HealthCheckSpeakerLowLevel = {
          keyName: "HealthCheckSpeakerLowLevel",
          keyValue: false,
          keyType: "boolean",
        };
        policy.fields.HealthCheckMicrophoneMute = {
          keyName: "HealthCheckMicrophoneMute",
          keyValue: false,
          keyType: "boolean",
        };
        policy.fields.HealthCheckMicrophoneLowLevel = {
          keyName: "HealthCheckMicrophoneLowLevel",
          keyValue: false,
          keyType: "boolean",
        };
        policy.fields.HealthCheckHasCamera = {
          keyName: "HealthCheckHasCamera",
          keyValue: false,
          keyType: "boolean",
        };
        policy.fields.HealthCheckHasInternetConnection = {
          keyName: "HealthCheckHasInternetConnection",
          keyValue: false,
          keyType: "boolean",
        };
        policy.fields.HealthCheckHasValareaConnection = {
          keyName: "HealthCheckHasValareaConnection",
          keyValue: false,
          keyType: "boolean",
        };
        policy.fields.SmtpSendHealthCheck = {
          keyName: "SmtpSendHealthCheck",
          keyValue: false,
          keyType: "boolean",
        };
      }
      if (policy.fields.SmtpSendHealthCheck?.keyValue == false) {
        policy.fields.SmtpSendHealthCheck = {
          keyName: "SmtpSendHealthCheckReceiver",
          keyValue: "",
          keyType: "string",
        };
        policy.fields.SmtpSendHealthCheckTime = {
          keyName: "SmtpSendHealthCheckTime",
          keyValue: 15,
          keyType: "int",
        };
      }
      if (policy.fields.RMSServerUrlToggle?.keyValue == false) {
        policy.fields.RMSServerUrl = {
          keyName: "RMSServerUrl",
          keyValue: "",
          keyType: "string",
        };
      }
      if (policy.fields.EnableRebootTask?.keyValue == false) {
        policy.fields.RebootTime = {
          keyName: "RebootTime",
          keyValue: "12:00 AM",
          keyType: "string",
        };
      }
    }
    return policy;
  },
};
