



























































import Vue from "vue";
import MainColumns from "@/components/templates/MainColumns.vue";
import { Group } from "@/models/group";
import PoliciesChooser from "@/components/organisms/policies/PoliciesChooser.vue";
import VaButton from "@/components/atoms/VaButton.vue";
import Notifications from "@/components/organisms/Notifications.vue";
import VaBack from "@/components/molecules/VaBack.vue";
import VaCard from "@/components/molecules/VaCard.vue";
import VaTextField from "@/components/atoms/VaTextField.vue";
import validationMixin from "@/components/mixins/validationMixin";

export default Vue.extend({
  name: "Groups",
  mixins: [validationMixin],
  components: {
    VaTextField,
    VaCard,
    VaBack,
    VaButton,
    MainColumns,
    PoliciesChooser,
    Notifications,
  },
  async beforeMount() {
    this.$store.dispatch("group/fetchGroup", this.$route.params.id);
  },
  computed: {
    group(): Group | undefined {
      return this.$store.getters["group/currentGroup"];
    },
  },
});
