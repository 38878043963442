
























import Vue from "vue";
import MainColumns from "@/components/templates/MainColumns.vue";
import { Building } from "@/models/building";
import RoomsMap from "@/components/organisms/RoomsMap.vue";
import { Marker } from "@/models/address";
import { latLng } from "leaflet";
import VaBack from "@/components/molecules/VaBack.vue";
import VaCard from "@/components/molecules/VaCard.vue";
import VaButton from "@/components/atoms/VaButton.vue";
import BuildingInfo from "@/components/organisms/buildings/BuildingInfo.vue";

export default Vue.extend({
  name: "BuildingsDetail",

  components: {
    VaCard,
    VaBack,
    MainColumns,
    VaButton,
    BuildingInfo,
    RoomsMap,
  },
  beforeMount() {
    this.$store.dispatch("room/getRooms"); //TODO filter rooms by ids
    this.$store.dispatch("building/getBuildings");
  },
  // beforeDestroy() {
  //   this.$store.commit("room/resetCurrentBuilding");
  // },
  computed: {
    center(): (lat: number, lng: number) => void {
      return this.$store.getters["building/currentBuilding"].lat &&
        this.$store.getters["building/currentBuilding"].lng
        ? latLng(
            this.$store.getters["building/currentBuilding"].lat,
            this.$store.getters["building/currentBuilding"].lng
          )
        : latLng(43.769562, 11.255814);
    },
    shouldUpdate(): boolean {
      return (
        this.$store.getters["building/buildings"].findIndex(
          (building: Building) => building.uuid === this.$route.params.id
        ) > -1
      );
    },
    buildings(): Building[] {
      return this.$store.getters["building/buildings"];
    },
    currentBuilding(): Building {
      //TODO refactor when API is ready
      return this.$store.getters["building/buildings"].find(
        (building: Building) => building.uuid === this.$route.params.id
      );
    },
    marker(): Marker[] {
      const currentBuilding = this.currentBuilding as Building;
      if (currentBuilding.lat && currentBuilding.lng) {
        return [
          {
            id: currentBuilding.uuid,
            name: currentBuilding.name as string,
            roomsCount: currentBuilding.roomsCount as number,
            position: {
              lat: currentBuilding.lat,
              lng: currentBuilding.lng,
            },
          },
        ];
      }
      return [];
    },
  },
  methods: {
    onEdit() {
      this.$store.commit("building/setCurrentBuilding", this.currentBuilding);
      this.$router.push(`/buildings/${this.currentBuilding.uuid}/edit`);
    },
  },
});
