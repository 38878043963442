

























import Vue from "vue";
import MainColumns from "@/components/templates/MainColumns.vue";
import BuildingForm from "@/components/organisms/BuildingForm.vue";
import { Building } from "@/models/building";
import RoomsMap from "@/components/organisms/RoomsMap.vue";
import { Marker } from "@/models/address";
import { latLng } from "leaflet";
import VaBack from "@/components/molecules/VaBack.vue";
import VaCard from "@/components/molecules/VaCard.vue";

export default Vue.extend({
  name: "BuildingsDetail",

  components: {
    VaCard,
    VaBack,
    MainColumns,
    BuildingForm,
    RoomsMap,
  },
  beforeMount() {
    this.$store.dispatch("room/getRooms");
    if (this.$store.getters["building/buildings"].length === 0)
      this.$store.dispatch("building/getBuildings");
  },
  computed: {
    center(): (lat: number, lng: number) => void {
      return this.$store.getters["building/currentBuilding"].lat &&
        this.$store.getters["building/currentBuilding"].lng
        ? latLng(
            this.$store.getters["building/currentBuilding"].lat,
            this.$store.getters["building/currentBuilding"].lng
          )
        : latLng(43.769562, 11.255814);
    },
    shouldUpdate(): boolean {
      return (
        this.$store.getters["building/buildings"].findIndex(
          (building: Building) => building.uuid === this.$route.params.id
        ) > -1
      );
    },
    currentBuilding(): Building {
      return this.$store.getters["building/currentBuilding"];
    },
    marker(): Marker[] {
      const currentBuilding = this.$store.getters["building/currentBuilding"];
      if (currentBuilding.lat && currentBuilding.lng) {
        return [
          {
            id: currentBuilding.uuid,
            name: currentBuilding.name,
            roomsCount: currentBuilding.roomsCount,
            position: {
              lat: currentBuilding.lat,
              lng: currentBuilding.lng,
            },
          },
        ];
      }
      return [];
    },
  },
});
