















import Vue from "vue";
import RoomRemoteManagement from "@/components/dashboard/rooms/room/RoomRemoteManagement.vue";
import RoomMeetingSettings from "@/components/dashboard/rooms/room/RoomMeetingSettings.vue";
import RoomActions from "@/components/dashboard/rooms/room/RoomActions.vue";
import RoomLastCommands from "@/components/dashboard/rooms/room/RoomLastCommands.vue";
import RoomUpdate from "@/components/dashboard/rooms/room/RoomUpdate.vue";
import RoomPolicies from "@/components/dashboard/rooms/room/RoomPolicies.vue";
import RoomNotices from "@/components/dashboard/rooms/room/RoomNotices.vue";
import { RoomDetail } from "@/models/room";
import RoomSuperadmin from "@/components/dashboard/rooms/room/RoomSuperAdmin.vue";

export default Vue.extend({
  name: "RoomDetailManagement",
  components: {
    RoomSuperadmin,
    RoomRemoteManagement,
    RoomMeetingSettings,
    RoomActions,
    RoomLastCommands,
    RoomUpdate,
    RoomPolicies,
    RoomNotices,
  },
  data() {
    return {
      panels: [] as Array<number>,
      polling: 0,
      error: "",
      updateRequiredDialog: false,
      init: false,
    };
  },
  // async beforeMount() {
  //   await this.$store.dispatch("group/fetchList");
  // },
  computed: {
    room(): RoomDetail {
      return this.$store.getters["room/room"];
    },
  },
});
