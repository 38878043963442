import { GroupedPolicy, PolicyGroupDeserializer } from "@/models/policy";
import i18n from "@/i18n";

import {
  FilterBySerializer,
  OptionsTable,
  QueryTable,
  SortBySerializer,
  TableConfig,
} from "@/models/table";

// export interface Group {
//   groupId?: string;
//   name: string;
//   description: string;
//   isDefault: boolean;
//   policies: GroupedPolicy[];
// }

/** ------------ new models ------------ **/

export interface Group {
  id?: string;
  name: string;
  description: string;
  policies: GroupedPolicy[];
  default: boolean;
}

export const groupConfig = {
  id: {
    dtoName: "groupId",
    sortable: false,
    filterable: false,
    label: i18n.t("common.id"),
    hideColumn: true,
  },
  default: {
    dtoName: "isDefault",
    sortable: false,
    filterable: false,
    label: "",
    hideColumn: true,
  },
  name: {
    dtoName: "name",
    sortable: true,
    filterable: true,
    label: i18n.t("common.name"),
    hideColumn: false,
  },
  description: {
    dtoName: "description",
    sortable: true,
    filterable: true,
    label: i18n.t("common.description"),
    hideColumn: false,
  },
  policies: {
    dtoName: "policies",
    sortable: false,
    filterable: false,
    label: i18n.t("common.policies"),
    hideColumn: true,
  },
} as TableConfig;

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
export function GroupDeserializer(input: any): Group {
  return {
    id: input.groupId,
    name: input.name,
    description: input.description,
    policies: input.policies ? PolicyGroupDeserializer(input.policies) : [],
    default: input.isDefault,
  };
}

export function GroupSerializer(group: Group): unknown {
  return {
    groupId: group.id,
    name: group.name,
    description: group.description,
    policies: group.policies,
    isDefault: group.default,
  };
}

export function GroupSearchSerializer(
  options: OptionsTable,
  searchString?: string
): QueryTable {
  return {
    limit: options.itemsPerPage,
    page: options.page,
    sortBy: SortBySerializer(options, groupConfig),
    filterBy: { values: FilterBySerializer(groupConfig, searchString) },
  };
}
