












































































































































































































































































import Vue, { VueConstructor } from "vue";
import FileUploader from "@/components/shared/FileUploader.vue";
import { FileFormatted } from "@/models/file";
import VaSwitch from "@/components/atoms/VaSwitch.vue";
import VaTextField from "@/components/atoms/VaTextField.vue";
import validationMixin from "@/components/mixins/validationMixin";
import { LoggerMixin } from "@/components/mixins/errorMixin";
import policiesMixin from "@/components/mixins/policiesMixin";
import { FieldValues } from "@/models/policy";

export default (Vue as VueConstructor<LoggerMixin>).extend({
  name: "HomeLauncherConfiguration",
  components: { VaTextField, VaSwitch, FileUploader },
  mixins: [validationMixin, policiesMixin],
  data() {
    return {
      fields: {} as { [key: string]: FieldValues },
      file: null,
      StartInstantModeOptions: [
        {
          value: 0,
          text: "no",
        },
        {
          value: 1,
          text: "yes",
        },
      ],
      languagesOptions: [
        //TODO: MOVE ME TO DEDICATED SERVICE?
        {
          value: "en-US",
          text: this.$t("common.english") as string,
          icon: require("@/assets/images/flags/en.png"),
        },
        {
          value: "it-IT",
          text: this.$t("common.italian") as string,
          icon: require("@/assets/images/flags/it.png"),
        },
        {
          value: "fr-FR",
          text: this.$t("common.french") as string,
          icon: require("@/assets/images/flags/fr.png"),
        },
        {
          value: "es-ES",
          text: this.$t("common.spanish") as string,
          icon: require("@/assets/images/flags/es.png"),
        },
        // {
        //   value: "ru-RU",
        //   text: this.$t("common.russian") as string,
        //   icon: require("@/assets/images/flags/ru.png"),
        // },
      ],
    };
  },
  methods: {
    uploadLogo(logo: FileFormatted) {
      this.fields.Logo = logo;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.onChange("file");
    },
    uploadBackgrounds(backgrounds: Array<FileFormatted>) {
      this.fields.Backgrounds = backgrounds;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.onChange("file");
    },
  },
});
