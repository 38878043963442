



























































































































import Vue from "vue";
import MainColumns from "@/components/templates/MainColumns.vue";
import { Tag } from "@/models/tag";
import { Group } from "@/models/group";
import {
  RoomOverallStateData,
  RoomOverallStatesData,
  roomConfig,
  RoomOverallState,
  RoomDetail,
} from "@/models/room";
import VaStateBadge from "@/components/molecules/VaStateBadge.vue";
import VaChip from "@/components/atoms/VaChip.vue";
import VaNoDataTag from "@/components/molecules/VaNoDataTag.vue";
import VaTableTop from "@/components/molecules/VaTableTop.vue";
import Notifications from "@/components/organisms/Notifications.vue";
import dataTableMixin from "@/components/mixins/dataTableMixin";
import VaDetailsButton from "@/components/molecules/VaDetailsButton.vue";
import { FilterBy } from "@/models/table";

export default Vue.extend({
  name: "RoomsList",
  mixins: [dataTableMixin],
  async beforeMount() {
    this.initFiltersByRoute();
    await this.$store.dispatch("tag/fetchList");
    await this.$store.dispatch("group/fetchList");
  },
  components: {
    VaDetailsButton,
    VaTableTop,
    MainColumns,
    VaStateBadge,
    VaChip,
    VaNoDataTag,
    Notifications,
  },
  data() {
    return {
      storeName: "room",
      tableConfig: roomConfig,
      filterTags: [],
      filterGroup: {} as Group,
      filterState: undefined as RoomOverallState | undefined,
      overallStatesData: RoomOverallStatesData as Array<RoomOverallStateData>,
    };
  },
  methods: {
    initFiltersByRoute() {
      if (this.$route.query?.overallState) {
        const state = RoomOverallStatesData.find(
          (data) => data.name === this.$route.query.overallState
        );
        this.filterState = state ? state.id : undefined;
      }
    },
  },
  computed: {
    groups(): Group[] {
      return this.$store.getters["group/groups"];
    },
    tags(): Tag[] {
      return this.$store.getters["tag/tags"];
    },
    rooms(): RoomDetail[] {
      const rooms = this.$store.getters["room/rooms"];
      return rooms.filter((room: RoomDetail) => {
        return !!room.name;
      });
    },
    filters(): FilterBy[] {
      const filters = this.filterTags.map((f) => {
        return {
          field: "tagId",
          value: f,
        } as FilterBy;
      });
      if (this.filterGroup?.id) {
        filters.push({ field: "groupId", value: this.filterGroup.id });
      }
      if (this.filterState != undefined) {
        filters.push({
          field: "overallState",
          value: this.filterState.toString(),
        });
      }
      return filters;
    },
  },
  watch: {
    filters: function (newVal): void {
      this.$store.dispatch("room/updateAdditionalFilters", newVal);
    },
  },
});
