var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.room
    ? _c(
        "div",
        [
          _c("div", { staticClass: "va-title text-center" }, [
            _vm._v(" " + _vm._s(_vm.$t("roomIssues.title")) + " ")
          ]),
          _vm.room.issues.length === 0
            ? _c("va-notification", { attrs: { type: "success" } }, [
                _vm._v(" " + _vm._s(_vm.$t("roomIssues.empty")) + " ")
              ])
            : _vm._e(),
          _vm._l(_vm.room.issues, function(issue, index) {
            return _c(
              "va-notification",
              {
                key: index,
                staticClass: "light issue",
                attrs: { type: "error" }
              },
              [
                _c("div", [
                  _c("div", { staticClass: "text-xs" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("formatDateTime")(issue.issueStartDate)) +
                        " "
                    )
                  ]),
                  _c("div", [
                    _vm._v(_vm._s(_vm.$t("roomIssues." + issue.issueTypeCode)))
                  ])
                ])
              ]
            )
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }