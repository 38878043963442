var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "va-title text-center mb-6" }, [
        _vm._v(" " + _vm._s(_vm.$t("common.notifications")) + " ")
      ]),
      !_vm.dashboard
        ? _c(
            "div",
            { staticClass: "d-flex align-center justify-center mt-4" },
            [
              _c("v-progress-circular", {
                attrs: { color: "accent", indeterminate: "" }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.dashboard
        ? [
            _vm.dashboard.pods.withIssues.length > 0
              ? _c("va-notification", { attrs: { type: "error" } }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-center justify-space-between flex-grow-1"
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("index.roomsWithIssues")))
                      ]),
                      _c(
                        "v-menu",
                        {
                          attrs: { "offset-y": "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            style: { color: "inherit" },
                                            attrs: { text: "", small: "" }
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("common.view")) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            3669399259
                          )
                        },
                        [
                          _c(
                            "v-list",
                            { attrs: { dense: "" } },
                            _vm._l(_vm.dashboard.pods.withIssues, function(
                              room,
                              index
                            ) {
                              return _c(
                                "v-list-item",
                                {
                                  key: index,
                                  attrs: {
                                    dense: "",
                                    link: "",
                                    to:
                                      "/rooms/" +
                                      room.podId +
                                      "/info?room=" +
                                      room.name
                                  }
                                },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(room.name))
                                  ])
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              : _vm._e(),
            _vm.dashboard.pods.withAgentOff.length > 0
              ? _c("va-notification", { attrs: { type: "error" } }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-center justify-space-between flex-grow-1"
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("index.meetingRoomOffline")))
                      ]),
                      _c(
                        "v-menu",
                        {
                          attrs: { "offset-y": "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            style: { color: "inherit" },
                                            attrs: { text: "", small: "" }
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("common.view")) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            3669399259
                          )
                        },
                        [
                          _c(
                            "v-list",
                            _vm._l(_vm.dashboard.pods.withAgentOff, function(
                              room,
                              index
                            ) {
                              return _c(
                                "v-list-item",
                                {
                                  key: index,
                                  attrs: {
                                    dense: "",
                                    link: "",
                                    to:
                                      "/rooms/" +
                                      room.podId +
                                      "/info?room=" +
                                      room.name
                                  }
                                },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(room.name))
                                  ])
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              : _vm._e(),
            _vm.dashboard.pods.withUpdate.length > 0
              ? _c("va-notification", { attrs: { type: "info" } }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-center justify-space-between flex-grow-1"
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("index.roomsWithUpdate")))
                      ]),
                      _c(
                        "v-menu",
                        {
                          attrs: { "offset-y": "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            style: { color: "inherit" },
                                            attrs: { text: "", small: "" }
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("common.view")) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            3669399259
                          )
                        },
                        [
                          _c(
                            "v-list",
                            _vm._l(_vm.dashboard.pods.withUpdate, function(
                              room,
                              index
                            ) {
                              return _c(
                                "v-list-item",
                                {
                                  key: index,
                                  attrs: {
                                    dense: "",
                                    link: "",
                                    to:
                                      "/rooms/" +
                                      room.podId +
                                      "/info?room=" +
                                      room.name
                                  }
                                },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(room.name))
                                  ])
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              : _vm._e()
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }