var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-columns",
    {
      scopedSlots: _vm._u([
        {
          key: "rightColumn",
          fn: function() {
            return [_c("notifications")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("v-data-table", {
        staticClass: "border-a pa-6 row-pointer rounded",
        attrs: {
          items: _vm.rooms,
          headers: _vm.generateHeaders(_vm.tableConfig),
          options: _vm.groupOptions,
          "server-items-length": _vm.$store.getters["room/totalItems"],
          "item-key": "id",
          loading: _vm.$store.getters["room/loading"],
          "footer-props": _vm.$store.getters["room/footerProps"],
          "multi-sort": "",
          "mobile-breakpoint": "0"
        },
        on: {
          "update:options": function($event) {
            _vm.groupOptions = $event
          },
          "click:row": function(item) {
            return _vm.$router.push({
              path: "/rooms/" + item.id,
              query: { room: item.name }
            })
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "top",
              fn: function() {
                return [
                  _c("va-table-top", {
                    attrs: { title: _vm.$t("roomsList.title") },
                    model: {
                      value: _vm.searchString,
                      callback: function($$v) {
                        _vm.searchString = $$v
                      },
                      expression: "searchString"
                    }
                  })
                ]
              },
              proxy: true
            },
            {
              key: "no-data",
              fn: function() {
                return [_c("va-no-data-tag")]
              },
              proxy: true
            },
            {
              key: "header.overallState",
              fn: function() {
                return [
                  _c("v-select", {
                    attrs: {
                      dense: "",
                      "single-line": "",
                      items: _vm.overallStatesData,
                      label: _vm.$t("common.state"),
                      "item-text": "label",
                      "item-value": "id",
                      clearable: ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "selection",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c(
                              "v-chip",
                              {
                                attrs: {
                                  color: item.color,
                                  "x-small": "",
                                  label: ""
                                }
                              },
                              [_c("span", [_vm._v(_vm._s(item.label) + "aa")])]
                            )
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.filterState,
                      callback: function($$v) {
                        _vm.filterState = $$v
                      },
                      expression: "filterState"
                    }
                  })
                ]
              },
              proxy: true
            },
            _vm.groups
              ? {
                  key: "header.group",
                  fn: function() {
                    return [
                      _c("v-select", {
                        attrs: {
                          clearable: "",
                          dense: "",
                          "single-line": "",
                          items: _vm.groups,
                          label: _vm.$t("common.group"),
                          "item-text": "name",
                          "item-value": "id",
                          "return-object": ""
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "selection",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _c(
                                    "v-chip",
                                    { attrs: { "x-small": "", label: "" } },
                                    [_c("span", [_vm._v(_vm._s(item.name))])]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1453429408
                        ),
                        model: {
                          value: _vm.filterGroup,
                          callback: function($$v) {
                            _vm.filterGroup = $$v
                          },
                          expression: "filterGroup"
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              : null,
            {
              key: "header.tags",
              fn: function() {
                return [
                  _c("v-select", {
                    staticStyle: { "max-width": "180px" },
                    attrs: {
                      dense: "",
                      "single-line": "",
                      items: _vm.tags,
                      label: _vm.$t("common.tags"),
                      multiple: "",
                      "item-text": "name",
                      "item-value": "id",
                      clearable: ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "selection",
                        fn: function(ref) {
                          var item = ref.item
                          var index = ref.index
                          return [
                            index === 0
                              ? _c(
                                  "v-chip",
                                  { attrs: { "x-small": "", label: "" } },
                                  [_c("span", [_vm._v(_vm._s(item.name))])]
                                )
                              : _vm._e(),
                            _vm.filterTags.length > 1
                              ? _c(
                                  "span",
                                  { staticClass: "grey--text text-caption" },
                                  [
                                    _vm._v(
                                      " (+" +
                                        _vm._s(_vm.filterTags.length - 1) +
                                        ") "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.filterTags,
                      callback: function($$v) {
                        _vm.filterTags = $$v
                      },
                      expression: "filterTags"
                    }
                  })
                ]
              },
              proxy: true
            },
            {
              key: "item.roomName",
              fn: function(ref) {
                var item = ref.item
                return [_vm._v(" " + _vm._s(item.name) + " ")]
              }
            },
            {
              key: "item.overallState",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("va-state-badge", {
                    attrs: {
                      size: "s",
                      type: _vm.overallStatesData.find(function(stateData) {
                        return item.overallState === stateData.id
                      }).name
                    }
                  })
                ]
              }
            },
            {
              key: "item.group",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("va-chip", {
                    attrs: {
                      text: item.group.name,
                      size: "s",
                      color: "secondary"
                    }
                  })
                ]
              }
            },
            {
              key: "item.tags",
              fn: function(ref) {
                var item = ref.item
                return _vm._l(item.tags, function(tag, index) {
                  return _c("va-chip", {
                    key: index,
                    staticClass: "my-1 mr-1",
                    attrs: { text: tag.name, size: "s", color: "secondary" }
                  })
                })
              }
            },
            {
              key: "item.actions",
              fn: function() {
                return [_c("va-details-button")]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }