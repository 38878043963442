var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "va-subtitle" }, [
        _vm._v(_vm._s(_vm.$t("roomPolicies.title")))
      ]),
      _vm.room
        ? _c(
            "va-card",
            [
              _c("policies-chooser", {
                attrs: { policies: _vm.room.policies, "is-room": "" },
                on: { applied: _vm.onApplied }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }