var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "nestedForm",
      attrs: { "lazy-validation": "" },
      on: {
        input: function($event) {
          return _vm.onChangeValidation(_vm.valid)
        }
      },
      model: {
        value: _vm.valid,
        callback: function($$v) {
          _vm.valid = $$v
        },
        expression: "valid"
      }
    },
    [
      _c(
        "div",
        [
          _c("div", { staticClass: "va-subtitle-s my-6" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("policiesGroups.appearance")) +
                " " +
                _vm._s(_vm.$t("common.settings")) +
                " "
            )
          ]),
          _c("v-divider", { staticClass: "mb-4" }),
          _c(
            "section",
            { staticClass: "interface-customization" },
            [
              _c("div", { staticClass: "va-subtitle-s mt-6" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("homeLauncherConfiguration.userInterfaceTitle")
                    ) +
                    " "
                )
              ]),
              _c("va-switch", {
                attrs: {
                  text: _vm.$t("homeLauncherConfiguration.customLogoActive")
                },
                on: { input: _vm.onChange },
                model: {
                  value: _vm.fields.CustomLogoActive,
                  callback: function($$v) {
                    _vm.$set(_vm.fields, "CustomLogoActive", $$v)
                  },
                  expression: "fields.CustomLogoActive"
                }
              }),
              _vm.fields.CustomLogoActive
                ? _c("file-uploader", {
                    staticClass: "mt-4",
                    attrs: {
                      files: _vm.fields.Logo ? [_vm.fields.Logo] : [],
                      title: _vm.$t(
                        "homeLauncherConfiguration.customLogoUploader"
                      ),
                      accept: [".png", ".jpg"],
                      "file-type": 2
                    },
                    on: { upload: _vm.uploadLogo }
                  })
                : _vm._e(),
              _c("va-switch", {
                attrs: {
                  text: _vm.$t(
                    "homeLauncherConfiguration.customBackgroundActive"
                  )
                },
                on: { input: _vm.onChange },
                model: {
                  value: _vm.fields.CustomBackgroundActive,
                  callback: function($$v) {
                    _vm.$set(_vm.fields, "CustomBackgroundActive", $$v)
                  },
                  expression: "fields.CustomBackgroundActive"
                }
              }),
              _vm.fields.CustomBackgroundActive
                ? _c("file-uploader", {
                    staticClass: "mt-4",
                    attrs: {
                      files: _vm.fields.Backgrounds
                        ? _vm.fields.Backgrounds
                        : [],
                      title: _vm.$t(
                        "homeLauncherConfiguration.customBackgroundUploader"
                      ),
                      multiple: "",
                      accept: [".png", ".jpg"],
                      "file-type": 1
                    },
                    on: { upload: _vm.uploadBackgrounds }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "section",
            { staticClass: "digital-signage" },
            [
              _c("div", { staticClass: "va-subtitle-s mt-6" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("homeLauncherConfiguration.digitalSignageTitle")
                    ) +
                    " "
                )
              ]),
              _c("va-switch", {
                attrs: {
                  text: _vm.$t("homeLauncherConfiguration.digitalSignageActive")
                },
                on: { input: _vm.onChange },
                model: {
                  value: _vm.fields.DigitalSignageActive,
                  callback: function($$v) {
                    _vm.$set(_vm.fields, "DigitalSignageActive", $$v)
                  },
                  expression: "fields.DigitalSignageActive"
                }
              }),
              _vm.fields.DigitalSignageActive
                ? [
                    _c("va-text-field", {
                      staticClass: "mt-4",
                      attrs: {
                        label: _vm.$t(
                          "homeLauncherConfiguration.webUrlBackground"
                        ),
                        rules: [_vm.required, _vm.url]
                      },
                      on: { input: _vm.onChange },
                      model: {
                        value: _vm.fields.WebUrlBackground,
                        callback: function($$v) {
                          _vm.$set(_vm.fields, "WebUrlBackground", $$v)
                        },
                        expression: "fields.WebUrlBackground"
                      }
                    })
                  ]
                : _vm._e()
            ],
            2
          ),
          _c(
            "section",
            { staticClass: "voice-commands" },
            [
              _c("div", { staticClass: "va-subtitle-s mt-6" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("policiesFields.VoiceCommands")) + " "
                )
              ]),
              _c("va-switch", {
                attrs: { text: _vm.$t("policiesFields.VoiceCommandsActive") },
                on: { input: _vm.onChange },
                model: {
                  value: _vm.fields.VoiceCommandsActive,
                  callback: function($$v) {
                    _vm.$set(_vm.fields, "VoiceCommandsActive", $$v)
                  },
                  expression: "fields.VoiceCommandsActive"
                }
              }),
              _vm.fields.VoiceCommandsActive
                ? [
                    _c("v-select", {
                      staticClass: "mt-4",
                      attrs: {
                        outlined: "",
                        dense: "",
                        attach: "",
                        items: _vm.languagesOptions,
                        "item-text": "text",
                        "item-value": "value",
                        label: _vm.$t("policiesFields.VoiceCommandsCultureKey"),
                        "hide-details": "",
                        rules: [_vm.required]
                      },
                      on: { change: _vm.onChange },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "selection",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c("v-img", {
                                  staticClass: "mr-2",
                                  attrs: { "max-width": "20", src: item.icon }
                                }),
                                _vm._v(" " + _vm._s(item.text) + " ")
                              ]
                            }
                          },
                          {
                            key: "item",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c("v-img", {
                                  staticClass: "mr-2",
                                  attrs: { "max-width": "20", src: item.icon }
                                }),
                                _vm._v(" " + _vm._s(item.text) + " ")
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        574943004
                      ),
                      model: {
                        value: _vm.fields.VoiceCommandsCultureKey,
                        callback: function($$v) {
                          _vm.$set(_vm.fields, "VoiceCommandsCultureKey", $$v)
                        },
                        expression: "fields.VoiceCommandsCultureKey"
                      }
                    }),
                    _c("va-switch", {
                      attrs: {
                        text: _vm.$t("policiesFields.DisableVoiceSuggestion")
                      },
                      on: { input: _vm.onChange },
                      model: {
                        value: _vm.fields.DisableVoiceSuggestion,
                        callback: function($$v) {
                          _vm.$set(_vm.fields, "DisableVoiceSuggestion", $$v)
                        },
                        expression: "fields.DisableVoiceSuggestion"
                      }
                    })
                  ]
                : _vm._e()
            ],
            2
          ),
          _c(
            "section",
            { staticClass: "auto-hide-menu" },
            [
              _c("div", { staticClass: "va-subtitle-s mt-6" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("policiesFields.AutoHideMainTaskBarTitle")) +
                    " "
                )
              ]),
              _c("va-switch", {
                attrs: {
                  text: _vm.$t("policiesFields.AutoHideMainTaskBarDescription")
                },
                on: { input: _vm.onChange },
                model: {
                  value: _vm.fields.AutoHideMainTaskBar,
                  callback: function($$v) {
                    _vm.$set(_vm.fields, "AutoHideMainTaskBar", $$v)
                  },
                  expression: "fields.AutoHideMainTaskBar"
                }
              })
            ],
            1
          ),
          _c(
            "section",
            { staticClass: "people-count" },
            [
              _c("div", { staticClass: "va-subtitle-s mt-6" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("homeLauncherConfiguration.peopleCountTitle")
                    ) +
                    " "
                )
              ]),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("homeLauncherConfiguration.peopleCountDescription")
                  ) +
                  " "
              ),
              _c("va-switch", {
                attrs: {
                  text: _vm.$t("homeLauncherConfiguration.peopleCountEnabled")
                },
                on: { input: _vm.onChange },
                model: {
                  value: _vm.fields.PeopleCountEnabled,
                  callback: function($$v) {
                    _vm.$set(_vm.fields, "PeopleCountEnabled", $$v)
                  },
                  expression: "fields.PeopleCountEnabled"
                }
              }),
              _vm.fields.PeopleCountEnabled
                ? _c("va-text-field", {
                    staticClass: "mt-4",
                    attrs: {
                      number: "",
                      label: _vm.$t(
                        "homeLauncherConfiguration.peopleCountRoomCapacity"
                      ),
                      rules: [
                        _vm.required,
                        _vm.minNumber(_vm.fields.PeopleCountRoomCapacity, 1),
                        _vm.maxNumber(_vm.fields.PeopleCountRoomCapacity, 30)
                      ]
                    },
                    on: { input: _vm.onChange },
                    model: {
                      value: _vm.fields.PeopleCountRoomCapacity,
                      callback: function($$v) {
                        _vm.$set(_vm.fields, "PeopleCountRoomCapacity", $$v)
                      },
                      expression: "fields.PeopleCountRoomCapacity"
                    }
                  })
                : _vm._e(),
              _vm.fields.PeopleCountEnabled
                ? _c("va-switch", {
                    attrs: {
                      text: _vm.$t(
                        "homeLauncherConfiguration.peopleCountOnScreenNotification"
                      )
                    },
                    on: { input: _vm.onChange },
                    model: {
                      value: _vm.fields.PeopleCountOnScreenNotification,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.fields,
                          "PeopleCountOnScreenNotification",
                          $$v
                        )
                      },
                      expression: "fields.PeopleCountOnScreenNotification"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "section",
            { staticClass: "wifi" },
            [
              _c("div", { staticClass: "va-subtitle-s" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("homeLauncherConfiguration.wifiTitle")) +
                    " "
                )
              ]),
              _c("va-switch", {
                attrs: {
                  text: _vm.$t("homeLauncherConfiguration.showWifiData")
                },
                on: { input: _vm.onChange },
                model: {
                  value: _vm.fields.ShowWifiData,
                  callback: function($$v) {
                    _vm.$set(_vm.fields, "ShowWifiData", $$v)
                  },
                  expression: "fields.ShowWifiData"
                }
              }),
              _vm.fields.ShowWifiData
                ? [
                    _c("va-text-field", {
                      staticClass: "mt-4",
                      attrs: {
                        label: _vm.$t("homeLauncherConfiguration.wifiSSID"),
                        rules: [_vm.required]
                      },
                      on: { input: _vm.onChange },
                      model: {
                        value: _vm.fields.WifiSSID,
                        callback: function($$v) {
                          _vm.$set(_vm.fields, "WifiSSID", $$v)
                        },
                        expression: "fields.WifiSSID"
                      }
                    }),
                    _c("va-text-field", {
                      staticClass: "mt-4",
                      attrs: {
                        label: _vm.$t("homeLauncherConfiguration.wifiPassword"),
                        rules: [_vm.required]
                      },
                      on: { input: _vm.onChange },
                      model: {
                        value: _vm.fields.WifiPassword,
                        callback: function($$v) {
                          _vm.$set(_vm.fields, "WifiPassword", $$v)
                        },
                        expression: "fields.WifiPassword"
                      }
                    })
                  ]
                : _vm._e()
            ],
            2
          ),
          _c(
            "section",
            { staticClass: "crestron" },
            [
              _c("div", { staticClass: "va-subtitle-s mt-6" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("homeLauncherConfiguration.crestron")) +
                    " "
                )
              ]),
              _c("va-switch", {
                attrs: {
                  text: _vm.$t("homeLauncherConfiguration.crestronEnabled")
                },
                on: { input: _vm.onChange },
                model: {
                  value: _vm.fields.CrestronEnabled,
                  callback: function($$v) {
                    _vm.$set(_vm.fields, "CrestronEnabled", $$v)
                  },
                  expression: "fields.CrestronEnabled"
                }
              }),
              _vm.fields.CrestronEnabled
                ? _c("va-text-field", {
                    staticClass: "mt-4",
                    attrs: {
                      label: _vm.$t("homeLauncherConfiguration.crestronUrl"),
                      rules: [_vm.required, _vm.url]
                    },
                    on: { input: _vm.onChange },
                    model: {
                      value: _vm.fields.CrestronUrl,
                      callback: function($$v) {
                        _vm.$set(_vm.fields, "CrestronUrl", $$v)
                      },
                      expression: "fields.CrestronUrl"
                    }
                  })
                : _vm._e(),
              _vm.fields.CrestronEnabled
                ? _c("va-text-field", {
                    staticClass: "mt-4",
                    attrs: {
                      label: _vm.$t(
                        "homeLauncherConfiguration.crestronUsername"
                      )
                    },
                    on: { input: _vm.onChange },
                    model: {
                      value: _vm.fields.CrestronUsername,
                      callback: function($$v) {
                        _vm.$set(_vm.fields, "CrestronUsername", $$v)
                      },
                      expression: "fields.CrestronUsername"
                    }
                  })
                : _vm._e(),
              _vm.fields.CrestronEnabled
                ? _c("va-text-field", {
                    staticClass: "mt-4",
                    attrs: {
                      label: _vm.$t(
                        "homeLauncherConfiguration.crestronPassword"
                      ),
                      password: ""
                    },
                    on: { input: _vm.onChange },
                    model: {
                      value: _vm.fields.CrestronPassword,
                      callback: function($$v) {
                        _vm.$set(_vm.fields, "CrestronPassword", $$v)
                      },
                      expression: "fields.CrestronPassword"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "section",
            { staticClass: "restart" },
            [
              _c("div", { staticClass: "va-subtitle-s mt-6" }, [
                _vm._v(" " + _vm._s(_vm.$t("policiesFields.Settings")) + " ")
              ]),
              _c("va-switch", {
                attrs: { text: _vm.$t("policiesFields.ShowSettings") },
                on: { input: _vm.onChange },
                model: {
                  value: _vm.fields.ShowSettings,
                  callback: function($$v) {
                    _vm.$set(_vm.fields, "ShowSettings", $$v)
                  },
                  expression: "fields.ShowSettings"
                }
              }),
              _c("va-switch", {
                attrs: { text: _vm.$t("policiesFields.ShowRestartDevice") },
                on: { input: _vm.onChange },
                model: {
                  value: _vm.fields.ShowRestartDevice,
                  callback: function($$v) {
                    _vm.$set(_vm.fields, "ShowRestartDevice", $$v)
                  },
                  expression: "fields.ShowRestartDevice"
                }
              }),
              _c("va-switch", {
                attrs: { text: _vm.$t("policiesFields.ShowRestartSoftware") },
                on: { input: _vm.onChange },
                model: {
                  value: _vm.fields.ShowRestartSoftware,
                  callback: function($$v) {
                    _vm.$set(_vm.fields, "ShowRestartSoftware", $$v)
                  },
                  expression: "fields.ShowRestartSoftware"
                }
              }),
              _c("va-switch", {
                attrs: { text: _vm.$t("policiesFields.ShowDisplaySwitch") },
                on: { input: _vm.onChange },
                model: {
                  value: _vm.fields.ShowDisplaySwitch,
                  callback: function($$v) {
                    _vm.$set(_vm.fields, "ShowDisplaySwitch", $$v)
                  },
                  expression: "fields.ShowDisplaySwitch"
                }
              }),
              _c("v-select", {
                staticClass: "mt-4",
                attrs: {
                  outlined: "",
                  attach: "",
                  rules: [_vm.required],
                  items: _vm.StartInstantModeOptions,
                  label: _vm.$t("policiesFields.StartInstantMode"),
                  "item-text": "text",
                  "item-value": "value"
                },
                on: { change: _vm.onChange },
                model: {
                  value: _vm.fields.StartInstantMode,
                  callback: function($$v) {
                    _vm.$set(_vm.fields, "StartInstantMode", $$v)
                  },
                  expression: "fields.StartInstantMode"
                }
              }),
              _c("va-switch", {
                attrs: { text: _vm.$t("policiesFields.Use24TimeFormat") },
                on: { input: _vm.onChange },
                model: {
                  value: _vm.fields.Use24TimeFormat,
                  callback: function($$v) {
                    _vm.$set(_vm.fields, "Use24TimeFormat", $$v)
                  },
                  expression: "fields.Use24TimeFormat"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }