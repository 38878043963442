import { Building } from "@/models/building";
import { Address } from "@/models/address";

export const mockAddress1 = {
  street: "via roma 1",
  city: "Milano",
  locality: "Mi",
  region: undefined,
  postalCode: "123456",
  country: "Italia",
  countryCode: "ITA",
  coordinates: {
    lat: 45.464664,
    lng: 9.18854,
  },
} as Address;

export const mockAddress2 = {
  street: "via roma 2",
  city: "Milano",
  locality: "Mi",
  region: undefined,
  postalCode: "123456",
  country: "Italia",
  countryCode: "ITA",
} as Address;

export const mockAddress3 = {
  street: "via roma 3",
  city: "Milano",
  locality: "Mi",
  region: undefined,
  postalCode: "123456",
  country: "Italia",
  countryCode: "ITA",
  coordinates: {
    lat: 45.4409311,
    lng: 9.0958763,
  },
} as Address;

export const mockAddress4 = {
  street: "via XX settembre 2",
  city: "Rimini",
  locality: "RN",
  region: undefined,
  postalCode: "47922",
  country: "Italia",
  countryCode: "ITA",
  coordinates: {
    lat: 44.059444,
    lng: 12.568333,
  },
} as Address;

export const mockBuildings = [
  {
    uuid: "12345",
    name: "Milan Building 1",
    roomsCount: 2,
    street: mockAddress1.street,
    city: mockAddress1.city,
    province: mockAddress1.locality,
    postalCode: mockAddress1.postalCode,
    country: mockAddress1.country,
    countryCode: mockAddress1.countryCode,
    lat: 45.464664,
    lng: 9.18854,
    floors: [],
    rooms: [],
  },
  {
    uuid: "123456",
    name: "Milan Building 2",
    roomsCount: 2,
    street: mockAddress2.street,
    city: mockAddress2.city,
    province: mockAddress2.locality,
    postalCode: mockAddress2.postalCode,
    country: mockAddress2.country,
    countryCode: mockAddress2.countryCode,
    floors: [],
    rooms: [],
  },
  {
    uuid: "1234567",
    name: "Milan Building 3",
    roomsCount: 4,
    street: mockAddress3.street,
    city: mockAddress3.city,
    province: mockAddress3.locality,
    postalCode: mockAddress3.postalCode,
    country: mockAddress3.country,
    countryCode: mockAddress3.countryCode,
    lat: 45.4409311,
    lng: 9.0958763,
    floors: [],
    rooms: [],
  },
  {
    uuid: "12345678",
    name: "Rimini Building 1",
    roomsCount: 1,
    street: mockAddress4.street,
    city: mockAddress4.city,
    province: mockAddress4.locality,
    postalCode: mockAddress4.postalCode,
    country: mockAddress4.country,
    countryCode: mockAddress4.countryCode,
    lat: 44.059444,
    lng: 12.568333,
    floors: ["floor1", "floor2", "floor3"],
    rooms: [
      {
        roomId: "ff743710-1dea-4195-9318-8919bf0eba95",
        name: "AdrianPersonalPc",
        floor: "floor1",
      },
    ],
  },
] as Building[];
