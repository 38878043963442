var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-columns",
    {
      scopedSlots: _vm._u([
        {
          key: "rightColumn",
          fn: function() {
            return [_c("room-issues")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "v-tabs",
        { staticClass: "va-card pa-0 mb-6" },
        [
          _c("v-tab", { attrs: { to: "/rooms/" + _vm.roomId + "/info" } }, [
            _vm._v("info")
          ]),
          _c(
            "v-tab",
            { attrs: { to: "/rooms/" + _vm.roomId + "/management" } },
            [_vm._v("management")]
          ),
          _c(
            "v-tab",
            { attrs: { to: "/rooms/" + _vm.roomId + "/equipment" } },
            [_vm._v("equipment")]
          ),
          _c(
            "v-tab",
            { attrs: { to: "/rooms/" + _vm.roomId + "/analytics" } },
            [_vm._v("analytics")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "va-title d-flex align-self-center" },
        [
          _c("va-back", {
            attrs: { target: "/rooms", tooltip: _vm.$t("roomDetail.back") }
          }),
          _vm.room
            ? _c("span", { staticClass: "ml-4" }, [
                _vm._v(_vm._s(_vm.room.name))
              ])
            : _vm._e()
        ],
        1
      ),
      _vm.room && !_vm.room.online
        ? _c(
            "v-alert",
            { staticClass: "light not-reachable", attrs: { type: "error" } },
            [_vm._v(" " + _vm._s(_vm.$t("roomDetail.notReachable")) + " ")]
          )
        : _vm._e(),
      _c("router-view")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }