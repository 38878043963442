import axios, { AxiosResponse } from "axios";
import apiService from "./apiService";
import { OptionsTable, QueryResult } from "@/models/table";
import {
  Group,
  GroupDeserializer,
  GroupSearchSerializer,
} from "@/models/group";

export default {
  async search(
    options: OptionsTable,
    searchString?: string
  ): Promise<QueryResult> {
    const data = GroupSearchSerializer(options, searchString);
    const res: AxiosResponse = await axios.post(
      apiService.group.searchUrl(),
      data
    );
    return {
      items: res.data.items,
      count: res.data.count,
    } as QueryResult;
  },

  async get(): Promise<Array<Group>> {
    const res = await axios.get(apiService.group.baseUrl());
    return res.data as Array<Group>;
  },

  async post(name: string, description: string): Promise<string> {
    const res = await axios.post(apiService.group.baseUrl(), {
      name,
      description,
    });
    return res.data as string;
  },

  async getDetails(groupId: string): Promise<Group> {
    const res = await axios.get(apiService.group.baseUrl() + "/" + groupId);
    return GroupDeserializer(res.data);
  },

  async applyPolicy(groupId: string, policyId: string): Promise<AxiosResponse> {
    return await axios.post(
      apiService.group.baseUrl() + "/" + groupId + "/policies/" + policyId
    );
  },

  async removePolicy(
    groupId: string,
    policyId: string
  ): Promise<AxiosResponse> {
    return await axios.delete(
      apiService.group.baseUrl() + "/" + groupId + "/policies/" + policyId
    );
  },

  async put(
    id: string,
    name: string,
    description: string
  ): Promise<AxiosResponse> {
    return await axios.put(apiService.group.baseUrl() + "/" + id, {
      name,
      description,
    });
  },

  async delete(id: string): Promise<AxiosResponse> {
    return await axios.delete(apiService.group.baseUrl() + "/" + id);
  },
};
