export interface Coordinates {
  lat: number;
  lng: number;
}

export interface Marker {
  id: string;
  name: string;
  roomsCount: number;
  position: Coordinates;
}

export interface Address {
  street?: string;
  city?: string;
  locality?: string;
  region?: string;
  postalCode?: string;
  country?: string;
  countryCode?: string;
  coordinates?: Coordinates;
  floors?: string[];
}

export default {
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
  deserialize(input: any): Address {
    return {
      street: input.street || "",
      city: input.city || "",
      locality: input.locality || "",
      region: input.region || "",
      postalCode: input.postalCode,
      country: input.countryName || "Italia",
      countryCode: input.countryCode || "ITA",
      coordinates: input.coordinates || undefined,
      floors: input.floors || [],
    };
  },
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
  requestSerialize(input: Address): any {
    return {
      city: input.city,
      locality: input.locality,
      region: input.region,
      postalCode: input.postalCode,
      countryCode: input.countryCode,
      countryName: input.country,
      street: input.street,
      floors: input.floors,
      coordinates: input.coordinates,
    };
  },
};
