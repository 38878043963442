import { convertHexToRGBA } from "@/utils";
import { RoomCapacityTooltip } from "@/models/capacity";
// import styleUtil from "@/utils/styleUtil";

// const theme = styleUtil.getTheme();

const alpha = 80;

export interface AnalyticTrendRawData {
  actualPeriod: number;
  beforePeriod: number;
  percentageChange: number;
}

export interface AnalyticTrendCard {
  value: number;
  trend: number;
  label: string;
  inverted?: boolean;
}

export interface AnalyticChartRawData {
  date: number;
  values: Array<number>;
}

export interface AnalyticDynamicChartRawData {
  labels: Array<string>;
  data: Array<AnalyticChartRawData>;
}

export interface ChartPalette {
  backgrounds: Array<string>;
  borders: Array<string>;
}

export const CHART_PALETTES = [
  {
    backgrounds: [
      convertHexToRGBA("#3C74A5", alpha),
      convertHexToRGBA("#88C16C", alpha),
      convertHexToRGBA("#6CA88A", alpha),
      convertHexToRGBA("#144594", alpha),
      convertHexToRGBA("#528F9C", alpha),
      convertHexToRGBA("#A2BCC1", alpha),
      convertHexToRGBA("#7798B5", alpha),
      convertHexToRGBA("#788B8F", alpha),
    ],
    borders: [
      "#3C74A5",
      "#88C16C",
      "#6CA88A",
      "#144594",
      "#528F9C",
      "#A2BCC1",
      "#7798B5",
      "#788B8F",
    ],
  },
  {
    backgrounds: [
      convertHexToRGBA("#573F74", alpha),
      convertHexToRGBA("#B76499", alpha),
      convertHexToRGBA("#E57CAF", alpha),
      convertHexToRGBA("#885085", alpha),
      convertHexToRGBA("#223464", alpha),
      convertHexToRGBA("#7181B0", alpha),
    ],
    borders: ["#573F74", "#B76499", "#E57CAF", "#885085", "#223464", "#7181B0"],
  },
  {
    backgrounds: [
      convertHexToRGBA("#2564AB", alpha),
      convertHexToRGBA("#5AA9DC", alpha),
      convertHexToRGBA("#7BCDF3", alpha),
      convertHexToRGBA("#3C86C4", alpha),
      convertHexToRGBA("#144594", alpha),
      convertHexToRGBA("#8EA9D4", alpha),
    ],
    borders: ["#2564AB", "#5AA9DC", "#7BCDF3", "#3C86C4", "#144594", "#8EA9D4"],
  },
  {
    backgrounds: [
      convertHexToRGBA("#6CAA5C", alpha),
      convertHexToRGBA("#E8A427", alpha),
      convertHexToRGBA("#E04A52", alpha),
      convertHexToRGBA("#3A4047", alpha),
    ],
    borders: ["#6CAA5C", "#E8A427", "#E04A52", "#3A4047"],
  },
  {
    backgrounds: [
      convertHexToRGBA("#144594", 70),
      convertHexToRGBA("#2564AB", 70),
      convertHexToRGBA("#3C86C4", 70),
      convertHexToRGBA("#5AA9DC", 70),
    ],
    borders: ["#144594", "#2564AB", "#3C86C4", "#5AA9DC"],
  },
] as Array<ChartPalette>;

export const BORDER_WIDTH = 0.8;

export const TICK_COLOR = "#ECEEF0";

export const AXE_COLOR = "#DEE1E5";

export const LABEL_COLOR = "#97A1AE";

export const MAX_BAR_THICKNESS = 50;

export interface ChartDataset {
  backgroundColor: string | Array<string>;
  borderColor?: string | Array<string>;
  data: Array<number>;
  label: string;
  borderWidth?: number;
  maxBarThickness?: number;
  processes?: Array<Array<string>>; // only for performance chart
  capacityData?: Array<RoomCapacityTooltip>; //only for capacity
  fill?: boolean;
}

export interface ChartData {
  labels: Array<string>;
  datasets: Array<ChartDataset>;
}

export interface Analytics {
  totalNotShow: AnalyticTrendRawData;
  totalMeetings: AnalyticTrendRawData;
  ongoingMeeting: AnalyticTrendRawData;
  averageMeetingDuration: AnalyticTrendRawData;
  averageStartTime: AnalyticTrendRawData;
  totalMeetingTime: AnalyticTrendRawData;
  earlyLate: Array<AnalyticChartRawData>;
  instantBooked: Array<AnalyticChartRawData>;
  meetingAirserver: Array<AnalyticChartRawData>;
  airserverProtocols: Array<AnalyticChartRawData>;
  offlineOnline: Array<AnalyticChartRawData>;
  applicationStats: AnalyticDynamicChartRawData;
  meetingsTypesStats: AnalyticDynamicChartRawData;
  videoConfStats: AnalyticDynamicChartRawData;
  peopleCount: AnalyticDynamicChartRawData;
}
