




























































import Vue from "vue";
import { RoomDetail } from "@/models/room";
import VaCard from "@/components/molecules/VaCard.vue";
import VaButton from "@/components/atoms/VaButton.vue";
import VaTextField from "@/components/atoms/VaTextField.vue";
import errorMixin from "@/components/mixins/errorMixin";
import validationMixin from "@/components/mixins/validationMixin";
import VaSwitch from "@/components/atoms/VaSwitch.vue";
import roomService from "@/services/roomService";
import { BuildData } from "@/models/builds";
import clone from "lodash/clone";

export default Vue.extend({
  name: "RoomSuperadmin",
  components: { VaSwitch, VaTextField, VaButton, VaCard },
  mixins: [errorMixin, validationMixin],
  data() {
    return {
      password: "",
      list: [] as BuildData[],
      internal: true,
      mockInternal: [
        "mock internal version 123456",
        "mock internal version 123456.1",
        "mock internal version 123456.12",
      ],
      mockProduction: [
        "mock production version 123456",
        "mock production version 123456.1",
        "mock production version 123456.12",
      ],
    };
  },
  methods: {
    async onSubmit(): Promise<void> {
      this.list = [];
      this.list = await roomService.searchBuilds(
        this.password,
        this.internal,
        this.room.id
      );
    },
    onInstall(build: BuildData): void {
      roomService.installBuild(build);
    },
  },
  computed: {
    room(): RoomDetail {
      return clone(this.$store.getters["room/room"]);
    },
  },
});
