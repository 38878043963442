



















































































































import Vue, { PropType } from "vue";
import { FileFormatted, FileType } from "@/models/file";
import FileService from "@/services/fileService";
import draggable from "vuedraggable";
import { bytesToSize } from "@/utils";

interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

export default Vue.extend({
  name: "FileUploader",
  components: { draggable },
  props: {
    title: {
      required: false,
      type: String,
    },
    files: {
      required: false,
      type: Array as PropType<Array<FileFormatted>>,
      default: () => {
        return [];
      },
    },
    accept: {
      required: false,
      type: Array as PropType<Array<string>>,
      default: () => {
        return ["any"];
      },
    },
    multiple: {
      required: false,
      type: Boolean,
      default: false,
    },
    maxSize: {
      required: false,
      type: Number,
      default: 10485760, // 10mb
    },
    fileType: {
      required: false,
      type: Number,
      default: FileType.UNKNOWN,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      uploadedFiles: [] as Array<FileFormatted>,
      processing: false,
      ordering: false,
      imageMimeTypes: ["image/png", "image/jpeg"],
    };
  },
  watch: {
    files: function (newVal) {
      this.uploadedFiles = newVal;
    },
  },
  // beforeMount() {
  //   this.uploadedFiles = this.files;
  // },
  methods: {
    reset(): void {
      (this.$refs.uploader as HTMLInputElement).value = "";
    },
    onSorted() {
      this.$emit("upload", this.uploadedFiles);
    },
    browseFiles() {
      if (this.disabled) return;
      (this.$refs.uploader as HTMLElement).click();
    },
    removeFile(index = -1): void {
      if (index < 0) {
        this.uploadedFiles = [];
        this.$emit("upload", null);
        this.reset();
      } else {
        this.uploadedFiles?.splice(index, 1);
        this.$emit("upload", this.uploadedFiles);
      }
    },
    async uploadFile(event: HTMLInputEvent) {
      this.processing = true;
      const processedFiles = [];
      if (event?.target?.files?.length) {
        try {
          for (const file of event.target.files as FileList) {
            if (file.size > this.maxSize) {
              this.$notification.error(
                "File " +
                  file.name +
                  " exceeds the maximum size allowed (" +
                  bytesToSize(this.maxSize) +
                  ")."
              );
              this.reset();
            } else {
              processedFiles.push(
                await FileService.upload(file, this.fileType)
              );
            }
          }
          if (this.multiple) {
            this.uploadedFiles = this.uploadedFiles.concat(processedFiles);
            this.$emit("upload", this.uploadedFiles);
          } else {
            if (processedFiles.length > 0) {
              this.uploadedFiles = [processedFiles[0]];
              this.$emit("upload", this.uploadedFiles[0]);
            }
          }
        } catch (e) {
          this.$notification.error(this.$t("common.uploadError"));
          this.$sentry.capture(e, "FileUploader", "uploadFile");
        } finally {
          this.processing = false;
        }
      } else {
        // file uploaded -> browse -> cancel
        this.processing = false;
      }
    },
  },
  computed: {
    classes(): Array<string> {
      const classes = [];
      if (this.disabled) {
        classes.push("disabled");
      }
      return classes;
    },
  },
});
