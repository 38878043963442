var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "roomupdate" } },
    [
      _c("div", { staticClass: "va-subtitle" }, [
        _vm._v(_vm._s(_vm.$t("roomUpdate.title")))
      ]),
      _vm.room
        ? _c(
            "va-card",
            [
              _vm.updateLaunched
                ? _c("v-alert", { attrs: { dense: "", type: "success" } }, [
                    _vm._v(" " + _vm._s(_vm.$t("roomUpdate.launched")) + " ")
                  ])
                : _vm._e(),
              _vm.updateLaunched
                ? _c("va-notification", { attrs: { type: "success" } }, [
                    _vm._v(" " + _vm._s(_vm.$t("roomUpdate.launched")) + " ")
                  ])
                : [
                    _vm.canUpdate
                      ? _c("va-notification", { attrs: { type: "info" } }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-center justify-space-between flex-grow-1"
                            },
                            [
                              _c("div", [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t("roomUpdate.new")) + " ("
                                  )
                                ]),
                                _c("strong", [
                                  _vm._v(_vm._s(this.room.lastVersion))
                                ]),
                                _c("span", [_vm._v(")")])
                              ]),
                              _c(
                                "v-btn",
                                {
                                  style: { color: "inherit" },
                                  attrs: {
                                    text: "",
                                    small: "",
                                    disabled: _vm.sending,
                                    loading: _vm.sending
                                  },
                                  on: { click: _vm.update }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("common.updateNow")) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      : _c("va-notification", { attrs: { type: "success" } }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-center justify-space-between flex-grow-1"
                            },
                            [
                              _c("div", [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("roomUpdate.updated")))
                                ])
                              ])
                            ]
                          )
                        ])
                  ],
              _vm.showFastchannel
                ? _c("va-switch", {
                    attrs: {
                      text: _vm.$t("roomUpdate.beta"),
                      loading: _vm.allowing,
                      disabled: _vm.allowing
                    },
                    on: { input: _vm.onAllowBeta },
                    model: {
                      value: _vm.room.betaEnabled,
                      callback: function($$v) {
                        _vm.$set(_vm.room, "betaEnabled", $$v)
                      },
                      expression: "room.betaEnabled"
                    }
                  })
                : _vm._e()
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }