var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-columns",
    {
      scopedSlots: _vm._u([
        {
          key: "rightColumn",
          fn: function() {
            return [_c("notifications")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("v-data-table", {
        staticClass: "border-a pa-6 row-pointer rounded",
        attrs: {
          headers: _vm.generateHeaders(_vm.tableConfig),
          items: _vm.$store.getters["task/tasks"],
          options: _vm.groupOptions,
          "server-items-length": _vm.$store.getters["task/totalItems"],
          "item-key": "ID",
          loading: _vm.$store.getters["task/loading"],
          "loading-text": _vm.$t("common.loading"),
          "footer-props": _vm.$store.getters["task/footerProps"],
          "multi-sort": "",
          "mobile-breakpoint": "0"
        },
        on: {
          "update:options": function($event) {
            _vm.groupOptions = $event
          },
          "click:row": function(item) {
            return _vm.$router.push("/rooms/task-scheduler/" + item.id)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "top",
            fn: function() {
              return [
                _c("va-table-top", {
                  attrs: {
                    title: _vm.$t("schedulersList.title"),
                    cta: _vm.$t("schedulersList.create")
                  },
                  on: {
                    ctaClick: function($event) {
                      return _vm.$router.push({
                        path: "/rooms/task-scheduler/new"
                      })
                    }
                  },
                  model: {
                    value: _vm.searchString,
                    callback: function($$v) {
                      _vm.searchString = $$v
                    },
                    expression: "searchString"
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "no-data",
            fn: function() {
              return [_c("va-no-data-tag")]
            },
            proxy: true
          },
          {
            key: "item.actions",
            fn: function() {
              return [_c("va-details-button")]
            },
            proxy: true
          },
          {
            key: "item.startDate",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(
                  " " + _vm._s(_vm._f("dateTime2string")(item.startDate)) + " "
                )
              ]
            }
          },
          {
            key: "item.endDate",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(
                  " " + _vm._s(_vm._f("dateTime2string")(item.endDate)) + " "
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }