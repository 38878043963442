var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-columns",
    {
      scopedSlots: _vm._u([
        {
          key: "rightColumn",
          fn: function() {
            return [_c("notifications")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        { staticClass: "va-title d-flex align-self-center" },
        [
          _c("va-back", {
            attrs: {
              target: "/rooms/task-scheduler",
              tooltip: _vm.$t("schedulersLauncher.back")
            }
          }),
          _c("span", { staticClass: "ml-4" }, [
            _vm._v(_vm._s(_vm.$t("schedulersLauncher.newTitle")))
          ])
        ],
        1
      ),
      _c(
        "va-card",
        [
          _c(
            "v-form",
            {
              ref: "createTaskForm",
              attrs: {
                "lazy-validation": "",
                onSubmit: "return false;",
                readonly: !!_vm.task.id
              },
              on: { submit: _vm.onSave },
              model: {
                value: _vm.valid,
                callback: function($$v) {
                  _vm.valid = $$v
                },
                expression: "valid"
              }
            },
            [
              _c("va-text-field", {
                staticClass: "mb-6",
                attrs: { rules: [_vm.required], label: _vm.$t("common.name") },
                model: {
                  value: _vm.task.name,
                  callback: function($$v) {
                    _vm.$set(_vm.task, "name", $$v)
                  },
                  expression: "task.name"
                }
              }),
              _c("div", { staticClass: "va-subtitle-s" }, [
                _vm._v(_vm._s(_vm.$t("common.commands")))
              ]),
              _c("commands-selector", {
                model: {
                  value: _vm.task.commands,
                  callback: function($$v) {
                    _vm.$set(_vm.task, "commands", $$v)
                  },
                  expression: "task.commands"
                }
              }),
              _c("commands-list", {
                model: {
                  value: _vm.task.commands,
                  callback: function($$v) {
                    _vm.$set(_vm.task, "commands", $$v)
                  },
                  expression: "task.commands"
                }
              }),
              _c("commands-apply-by", {
                staticClass: "mt-6",
                model: {
                  value: _vm.targets,
                  callback: function($$v) {
                    _vm.targets = $$v
                  },
                  expression: "targets"
                }
              }),
              _c("commands-schedule", {
                staticClass: "mt-6",
                model: {
                  value: _vm.task.scheduleOptions,
                  callback: function($$v) {
                    _vm.$set(_vm.task, "scheduleOptions", $$v)
                  },
                  expression: "task.scheduleOptions"
                }
              }),
              _c(
                "div",
                { staticClass: "d-flex mt-6" },
                [
                  _vm.task.id
                    ? _c("va-button", {
                        staticClass: "ml-auto",
                        attrs: { cta: _vm.$t("common.delete"), color: "error" },
                        on: {
                          click: function($event) {
                            return _vm.$store.dispatch("task/deleteCurrentTask")
                          }
                        }
                      })
                    : _c("va-button", {
                        staticClass: "ml-auto",
                        attrs: {
                          disabled: !_vm.taskValid,
                          submit: "",
                          loading: _vm.$store.getters["task/loading"],
                          cta: _vm.$t("common.save")
                        }
                      })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }