var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-columns",
    {
      scopedSlots: _vm._u([
        {
          key: "rightColumn",
          fn: function() {
            return [_c("live-data")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("va-kpi", {
                attrs: {
                  value: "80%",
                  icon: "vi vi-heartbeat-2",
                  "icon-color": "error",
                  title: "Health",
                  to: "/analytics/health"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            [
              _c("va-kpi", {
                attrs: {
                  value: "10",
                  icon: "vi vi-award",
                  "icon-color": "primary",
                  "trend-value": "-15%",
                  title: "Quality",
                  trend: "negative",
                  "trend-color": "error",
                  to: "/analytics/quality"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            [
              _c("va-kpi", {
                attrs: {
                  icon: "vi vi-chart-pie",
                  "icon-color": "pink",
                  value: "120",
                  trend: "positive",
                  "trend-value": "+5%",
                  title: "Usage",
                  "trend-color": "success",
                  to: "/analytics/usage"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            [
              _c("va-kpi", {
                attrs: {
                  icon: "vi vi-leaf",
                  "icon-color": "success",
                  value: "10",
                  "trend-value": "0%",
                  title: "Green",
                  "trend-color": "neutral",
                  to: "/analytics/green"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            [
              _c("va-kpi", {
                attrs: {
                  icon: "vi vi-cloudscale",
                  "icon-color": "purple",
                  value: "10%",
                  "trend-value": "-1%",
                  title: "Efficency",
                  "trend-color": "warning",
                  to: "/analytics/efficency"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-6" },
        [
          _c("div", { staticClass: "text-3xl font-bold mb-4" }, [
            _vm._v("Organization map")
          ]),
          _c("rooms-map")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }