var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-columns",
    {
      scopedSlots: _vm._u([
        {
          key: "rightColumn",
          fn: function() {
            return [_c("notifications")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        { staticClass: "va-title d-flex align-self-center" },
        [
          _c("va-back", {
            attrs: {
              target: "/rooms/groups",
              tooltip: _vm.$t("groupsList.back")
            }
          }),
          _c("span", { staticClass: "ml-4" }, [
            _vm._v(_vm._s(_vm.$t("groupsList.editTitle")))
          ])
        ],
        1
      ),
      _vm.group
        ? _c(
            "va-card",
            [
              _c(
                "v-form",
                {
                  ref: "editGroupForm",
                  attrs: { "lazy-validation": "", onSubmit: "return false;" },
                  on: {
                    submit: function($event) {
                      return _vm.$store.dispatch("group/updateCurrentGroup")
                    }
                  },
                  model: {
                    value: _vm.valid,
                    callback: function($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  _c("va-text-field", {
                    staticClass: "mb-6",
                    attrs: {
                      rules: [_vm.required],
                      label: _vm.$t("common.name")
                    },
                    model: {
                      value: _vm.group.name,
                      callback: function($$v) {
                        _vm.$set(_vm.group, "name", $$v)
                      },
                      expression: "group.name"
                    }
                  }),
                  _c("va-text-field", {
                    staticClass: "mb-6",
                    attrs: {
                      rules: [_vm.required],
                      label: _vm.$t("common.description")
                    },
                    model: {
                      value: _vm.group.description,
                      callback: function($$v) {
                        _vm.$set(_vm.group, "description", $$v)
                      },
                      expression: "group.description"
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "text-right mt-6" },
                    [
                      _c("va-button", {
                        staticClass: "mr-4",
                        attrs: {
                          disabled: !_vm.valid,
                          cta: _vm.$t("common.delete"),
                          color: "error"
                        },
                        on: {
                          click: function($event) {
                            return _vm.$store.dispatch(
                              "group/deleteCurrentGroup"
                            )
                          }
                        }
                      }),
                      _c("va-button", {
                        attrs: {
                          disabled: !_vm.valid,
                          submit: "",
                          loading: _vm.$store.getters["group/loading"],
                          cta: _vm.$t("common.save")
                        }
                      })
                    ],
                    1
                  ),
                  _c("div", { staticClass: "va-subtitle" }, [
                    _vm._v(_vm._s(_vm.$t("common.policies")))
                  ]),
                  _vm.group.policies
                    ? _c("policies-chooser", {
                        attrs: {
                          policies: _vm.group.policies,
                          "group-id": _vm.group.id,
                          "hide-icons": true
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }