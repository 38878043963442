var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "va-card",
    [
      _c("v-data-table", {
        staticClass: "row-pointer",
        attrs: {
          headers: _vm.generateHeaders(_vm.tableConfig),
          items: _vm.$store.getters["policy/policies"],
          options: _vm.groupOptions,
          "server-items-length": _vm.$store.getters["policy/totalItems"],
          "item-key": "ID",
          loading: _vm.$store.getters["policy/loading"],
          "loading-text": _vm.$t("common.loading"),
          "footer-props": _vm.$store.getters["policy/footerProps"],
          "multi-sort": "",
          "mobile-breakpoint": "0"
        },
        on: {
          "update:options": function($event) {
            _vm.groupOptions = $event
          },
          "click:row": function(item) {
            return _vm.$router.push("/rooms/policies/" + item.policyId)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "top",
            fn: function() {
              return [
                _c("va-table-top", {
                  attrs: { title: _vm.title, cta: _vm.$t("common.policy") },
                  on: {
                    ctaClick: function($event) {
                      return _vm.$router.push({ path: "/rooms/policies/new" })
                    }
                  },
                  model: {
                    value: _vm.searchString,
                    callback: function($$v) {
                      _vm.searchString = $$v
                    },
                    expression: "searchString"
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "no-data",
            fn: function() {
              return [_c("va-no-data-tag")]
            },
            proxy: true
          },
          {
            key: "item.group",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("va-chip", {
                  attrs: { text: item.groupName, size: "s", color: "secondary" }
                })
              ]
            }
          },
          {
            key: "item.actions",
            fn: function() {
              return [_c("va-details-button")]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }