








































































import Vue from "vue";
import MainColumns from "@/components/templates/MainColumns.vue";
import VaKpi from "@/components/molecules/VaKpi.vue";
import RoomsMap from "@/components/organisms/RoomsMap.vue";
import LiveData from "@/components/organisms/LiveData.vue";

export default Vue.extend({
  name: "home_dashboard",
  components: { MainColumns, VaKpi, RoomsMap, LiveData },
});
