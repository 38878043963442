import Vue, { PropType } from "vue";
import { FieldValues, Policy, PolicyField } from "@/models/policy";
import cloneDeep from "lodash/cloneDeep";

export default Vue.extend({
  data() {
    return {
      fields: {} as { [key: string]: FieldValues },
    };
  },
  props: {
    value: {
      type: Object as PropType<{ policy: Policy; valid: boolean }>,
      required: true,
    },
  },
  methods: {
    validate(): void {
      (this.$refs.nestedForm as Vue & { validate: () => boolean }).validate();
    },
    extractFields(fields: { [key: string]: PolicyField }): {
      [key: string]: FieldValues;
    } {
      const res = {} as any;
      Object.keys(fields).forEach(function (key) {
        res[key] = fields[key].keyValue;
      });
      return res;
    },
    onChangeValidation(valid: boolean): void {
      const res = cloneDeep(this.value);
      res.valid = valid;
      this.$emit("input", res);
    },
    onChange(type = "string"): void {
      const res = cloneDeep(this.value);
      for (const key in this.fields) {
        if (!res.policy.fields) {
          res.policy.fields = {};
        }
        if (res.policy.fields[key]) {
          //edit
          res.policy.fields[key].keyValue = this.fields[key];
        } else {
          //creation
          res.policy.fields[key] = {
            keyName: key,
            keyValue: this.fields[key],
            keyType: type, //hack: on creation we don't know the key type, BE don't use it
          };
        }
      }
      this.$emit("input", res);
    },
  },
  beforeMount() {
    if (this.value.policy.fields) {
      this.fields = this.extractFields(this.value.policy.fields);
    }
  },
  watch: {
    value: {
      handler: function (val) {
        if (val.policy.fields) {
          this.fields = this.extractFields(val.policy.fields);
        }
      },
      deep: true,
    },
  },
});
