var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "va-subtitle" }, [
        _vm._v(_vm._s(_vm.$t("roomSuperadmin.title")))
      ]),
      _c(
        "va-card",
        [
          _vm._v(" " + _vm._s(_vm.$t("roomSuperadmin.description")) + " "),
          _c(
            "v-form",
            {
              ref: "superAdminForm",
              attrs: { "lazy-validation": "", onSubmit: "return false;" },
              on: { submit: _vm.onSubmit },
              model: {
                value: _vm.valid,
                callback: function($$v) {
                  _vm.valid = $$v
                },
                expression: "valid"
              }
            },
            [
              _c(
                "div",
                { staticClass: "mt-4 flex align-center" },
                [
                  _c("va-text-field", {
                    staticClass: "mr-6",
                    attrs: {
                      rules: [_vm.required],
                      label: _vm.$t("common.password"),
                      password: "",
                      dense: ""
                    },
                    model: {
                      value: _vm.password,
                      callback: function($$v) {
                        _vm.password = $$v
                      },
                      expression: "password"
                    }
                  }),
                  _c("va-button", {
                    attrs: {
                      submit: "",
                      disabled: !_vm.password,
                      cta: "submit"
                    }
                  })
                ],
                1
              )
            ]
          ),
          _vm.list.length > 0
            ? _c(
                "div",
                { staticClass: "mt-4 flex" },
                [
                  _c("va-switch", {
                    staticClass: "flex-grow-1",
                    attrs: { text: "internal" },
                    model: {
                      value: _vm.internal,
                      callback: function($$v) {
                        _vm.internal = $$v
                      },
                      expression: "internal"
                    }
                  }),
                  _c("va-button", {
                    attrs: {
                      disabled: !_vm.password,
                      loading: _vm.$store.getters["mfa/isLoading"],
                      cta: "reload",
                      color: "secondary"
                    },
                    on: { click: _vm.onSubmit }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.list.length > 0
            ? _c(
                "div",
                { staticClass: "border-a pa-4" },
                _vm._l(_vm.list, function(item) {
                  return _c(
                    "div",
                    {
                      key: item.name,
                      staticClass:
                        "\n          d-flex\n          justify-space-between\n          align-center\n          my-2\n          border-b\n          pb-2\n          mb-2\n        "
                    },
                    [
                      _vm._v(" " + _vm._s(item.name) + " "),
                      _c("div", { staticClass: "flex-lg-grow-1" }),
                      _c("va-button", {
                        attrs: { size: "s", cta: "install" },
                        on: {
                          click: function($event) {
                            return _vm.onInstall(item)
                          }
                        }
                      })
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }